<i18n locale="th" lang="yaml" >
select.company.placeholder.default : "เลือกศูนย์บริการ"
</i18n>

<template>
	<a-select
		allow-clear
		class="myselect-companies"
		:get-popup-container="getPopupContainer"
		:placeholder="displayCompanyPlaceholder"
		:value="value"
		mode="multiple"
		:disabled="disabled"
		@change="handleChange">
		<a-select-option v-for="company in listCompanies" :key="company.id" :value="company.id">
			{{company.name}}
		</a-select-option>
	</a-select>
</template>

<script>
import {Select} from "ant-design-vue"
import {mapGetters, mapState} from "vuex"
import {isStringEmpty} from "@utils/stringUtil"
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		"a-select" : Select,
		"a-select-option" : Select.Option,
	} ,
	mixins : [PopupMixin] ,
	props : {
		defaultValue : {
			type : null,
			default : () => []
		} ,
		placeholder : {
			type : String,
			default : null
		} ,
		disabled : {
			type : Boolean,
			default : false,
		} ,
		allowClear : {
			type : Boolean,
			default : false,
		} ,
		activeOnly : {
			type : Boolean,
			default : true,
		} ,
		excludeMine : {
			type : Boolean ,
			default : false
		}
	} ,
	data() {
		return {
			value: undefined,
			allSelected: true
		}
	} ,
	computed : {
		...mapState('user',['companies']) ,
		...mapState('auth',['currentCompany']) ,
		...mapGetters('user',['getCompanyById','listActiveOnly']) ,
		displayCompanyPlaceholder() {
			this.addAllOption()
			return isStringEmpty(this.placeholder) ? this.$t('select.company.placeholder.default') : this.placeholder
		} ,
		listCompanies() {
			if (this.$isManagement(this.currentCompany.company_type)) {
				return this.companies.filter((c) => {
					if (this.activeOnly && c.company_status != 'active')
						return false
					if (this.excludeMine && c.id == this.currentCompany.id)
						return false
					return true
				})
			} else {
				return this.companies.filter((c) => {
					if (this.activeOnly && c.company_status != 'active')
						return false
					if (this.excludeMine && c.id == this.currentCompany.id)
						return false
					if (!this.currentCompany.access_company_list.includes(c.id) && c.id != this.currentCompany.id)
						return false
					return true
				})
			}
		}
	} ,
	mounted() {
		this.value = this.defaultValue
	} ,
	methods : {
		handleChange(val) {
			const selectAll = 'All'
			if (val.findIndex(id => id === selectAll) < 0) {
				this.value = val
			} else {
				this.value = []
			}
			this.$emit('change',this.value);
		},
		addAllOption() {
			const selectAll = 'All'
			this.listCompanies.unshift({name: selectAll, id: selectAll})
		},
	}
}
</script>

<style lang="less" scoped>
.myselect-companies {
	min-width: 150px;
	max-width: 1000px;
}
</style>
